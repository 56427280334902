import { render, staticRenderFns } from "./Housekeeping.vue?vue&type=template&id=2d6b2f46&"
import script from "./Housekeeping.vue?vue&type=script&lang=js&"
export * from "./Housekeeping.vue?vue&type=script&lang=js&"
import style0 from "./Housekeeping.vue?vue&type=style&index=0&id=2d6b2f46&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports